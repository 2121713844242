const en_US = {
    LABEL_CONNECT_WALLET: "Connect Wallet",
    LABEL_RESOURCE: "Resources",
    LABEL_COMMUNITY: "Communities",
    LABEL_CONTACT: "Contact Us",
    LP_LIST_TITLE: "Swaps",
    LP_TRADING_PAIR: "Trading Pair",
    LP_SYX_PRICE: "Price",
    LP_MY_SHARE: "My Shares",
    LP_DEPOSIT: "Add Liquidity",
    LP_DEPOSIT_WITHDRAW_REWARD: "Confirm",
    LP_WITHDRAW: "Confirm",
    LP_SWAP: "Swap",
    RP_LIST_TITLE: "Reward Pools",
    RP_TYPE: "Name",
    RP_ROI: "ROI",
    RP_MY_REWARDS: "My Rewards",
    RP_MY_STAKES: "My Stakes",
    RP_STAKE: "Stake",
    RP_UNSTAKE: "Untake",
    RP_WITHDRAW_REWARDS: "Claim Now",
    POPUP_TITLE_DEPOSIT: "Add Liquidity",
    POPUP_TITLE_WITHDRAW: "Remove Liquidity",
    POPUP_WALLET_BALANCE: "Available Balance",
    POPUP_DEPOSITABLE_AMOUNT: "Depositable amount",
    POPUP_WITHDRAWABLE_AMOUNT: "Withdrawable amount",
    POPUP_TRADEABLE_AMOUNT: "Tradeable amount",
    POPUP_INPUT_AMOUNT: "Amount",
    POPUP_INPUT_MAX: "MAX",
    POPUP_INPUT_TOKEN: "Token",
    POPUP_DEPOSIT_RESULT: "My shares in the pool (estimated): ",
    POPUP_WITHDRAW_RESULT: "New balance (estimated): ",
    POPUP_ACTION_DEPOSIT: "Add Liquidity",
    POPUP_ACTION_WITHDRAW: "Remove Liquidity",
    POPUP_TITLE_STAKE: "Stake to Reward Pool",
    POPUP_TITLE_UNSTAKE: "Remove Liquidity",
    POPUP_STAKE_BALANCE: "Current stake balance",
    POPUP_AVAILABLE_STAKE: "Available stakes",
    POPUP_STAKE_NEW_BALANCE: "New stake balance (estimated)",
    POPUP_TITLE_SWAP: "Swap",
    POPUP_LABEL_FROM: "From",
    POPUP_LABEL_TO: "To (estimated)",
    POPUP_LABEL_SWAP_RATE: "1 {tokenFrom} = {rate} {tokenTo}",
    POPUP_ACTION_CONFIRM: "Confirm",
    TOTAL_UNSTAKED_SHARE: "Total Available Shares",
    MY_STAKING_APR: "My Avgerage APR",
    AVG_STAKING_APR: "Average APR",
    TOTAL_REWARDS: "My Total Rewards",
    WITHDRAWABLE_REWARDS: "Claimable Rewards",
    END_TIME: "End Time",
    WALLET_BALANCE: "Wallet Balances",
    INVESTABLE_AMOUNT: "Investable Amount",
    DEPOSIT_INCENTIVE_PLAN: "Deposit Now",
    RATIO: "Ratio",
    ACTION: "Action",
    STAKING_TIP: "Deposit VLX to the pools you select to earn rewards",
    WITHDRAW_REWARDS_TIP:
        "Please find the reward token SYX in the wallet after claimed",
    UNIT_PRICE: "Unit Price",
    HOME_TITLE: "Bringing real-world assets to blockchain",
    HOME_SUBTITLE:
        "Symblox is a decentralized synthetic asset issuance and trading protocol",
    JOIN: "Join",
    TOTAL_SUPPLY: "Total supply",
    HOLD: "Staked",
    TOTAL_STAKE_AFTER_DEPOSIT: "Total amount after deposit",
    TOTAL_STAKE: "My Deposit",
    TRADE_ERROR_BALANCE: "Available balance not enough",
    SEE_DETAIL: "See detail",
    REWARD_DISTRIBUTION_RATIO: "Reward distribution ratio",
    NETWORK_ERROR:
        "Current newtwork is not supported, please switch to {requiredNetwork} (RPC URL: {rpcUrl})",
    POPUP_WITHDRAW_AMOUNT: "Amount to withdraw",
    POPUP_WITHDRAW_REWARD: "Rewards to claim",
    RISK_WARNING:
        "This contract is under auditing. PLEASE REVIEW THE CONTRACT BEFORE PROCEEDING.",
    COMMUNITY: "Community",
    CONTRACT: "Contract",
    SYX_TOKEN: "SYX (V2)",
    USDT_TOKEN: "USDT on VELAS",
    WETH_TOKEN: "WETH on VELAS",
    WVLX_TOKEN: "VLX Escrow",
    DEV_FUND: "Dev Fund",
    REFERRER: "Referrer Address",
    WALLET_CONNECT_TO: "Connected to {network}",
    WALLET_DISCONNECT: "Discconnect",
    EXCHANGE: "Exchange",
    EXCHANGE_TITLE: "Redeem new SYX tokens for Symblox yield farming 2.0",
    EXCHANGE_SUB_TITLE:
        "How many SYX tokens would you like to redeem for the new ones?",
    EXCHANGE_TIP: "Enter the amount you would like to exchange",
    EXCHANGE_RATE: "Old SYX to new SYX exchange rate",
    EXCHANGE_TOTAL_SUPPLY: "Max old SYX to new SYX liquidity",
    EXCHANGE_WALLET_BALANCE: "Redeemable balance: {amount}",
    DAPP_MINING_OLD: "Yield Farming (V1)",
    DAPP_MINING_NEW: "New Yield Farming",
    SYX_TOKEN_EXCHANGE: "Redeem New SYX",
    DAPP_CROSS_CHAIN: "Cross-Chain",
    V1: "V1",
    LANGUAGE: "Language"
};
export default en_US;
